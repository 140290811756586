import { module } from 'modujs';
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        this.vm = createApp({
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                return {
                    email: '',
                    password: '',
                    remember: false,
                }
            },
            validations() {
                return {
                    email: {
                        required,
                        email
                    },
                    password: {
                        required,
                    },
                    remember: {}
                }
            },
            mounted: () => {
                this.call('update', null, 'Scroll')
            },
            methods: {
                showPassword() {
                    this.$refs.passwordInput.type = 'text'

                    const hidePassword = () => {
                        this.$refs.passwordInput.type = 'password'
                        document.removeEventListener('mouseup', hidePassword)
                    }

                    document.addEventListener('mouseup', hidePassword)
                },
                openModal: (modalId) => {
                    this.call('open', modalId, 'Modal')
                },
                async submit () {
                    const isFormCorrect = await this.v$.$validate()

                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form

                    // TEMPORARY
                    alert('submit login form')
                    window.location.href = '/dashboard.php'
                }
            },
        }).mount(this.el)
    }
}
