/******************************************
 *
 * Constants
 *
 ******************************************/


export const MODAL = Object.freeze({
    PROFILE: 'profile',
    FORGOT_PASSWORD: 'forgot-password'
})
