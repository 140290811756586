import { module } from 'modujs';
import { gsap } from 'gsap/all'

export default class extends module {
    constructor(m) {
        super(m);

        this.ACTIVE_CLASS = 'is-open'

        this.$trigger = this.$('trigger')[0]
        this.$container = this.$('container')[0]
        this.$content = this.$('content')[0]

        this.events = {
            click: {
                'trigger': 'toggle'
            }
        }
    }

    init() {

        // Set default styles
        gsap.set(this.$trigger, { cursor: 'pointer'})

        // Open if has active class
        if(this.el.classList.contains(this.ACTIVE_CLASS)) {
            this.open(0)
        } else {
            this.close(0)
        }

        this.triggerKeyDownBind = (e) => {
            if(e.code == 'Space' || e.code == 'Enter') {
                this.toggle()
            }
        }
        this.$trigger.addEventListener('keydown', this.triggerKeyDownBind)
    }

    toggle() {
        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open(duration=1) {

        // Get content height
        const height = this.$content.offsetHeight

        // Slide down and show content
        gsap.to(this.$container, {
            duration,
            height,
            opacity: 1,
            ease: 'expo.inOut',
            onComplete: () => {
                gsap.set(this.$container, { height: 'auto' })

                // Update scroll position
                this.call('update', null, 'Scroll')
            }
        })

        // Add active class
        this.el.classList.add(this.ACTIVE_CLASS);

        this.isOpen = true
    }

    close(duration=1) {

        // Slide up and hide content
        gsap.to(this.$container, {
            duration,
            height: 0,
            opacity: 0,
            ease: 'expo.inOut',
            onComplete: () => {
                // Update scroll position
                this.call('update', null, 'Scroll')
            }
        })

        // Remove active class
        this.el.classList.remove(this.ACTIVE_CLASS);

        this.isOpen = false
    }

    destroy() {
        this.$trigger.removeEventListener('keydown', this.triggerKeyDownBind)
    }
}
