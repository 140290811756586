import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                close: 'close'
            }
        }

        this.modalId = this.getData('id')
    }

    open(id) {

        if(id !== this.modalId) {
            return
        }

        this.el.classList.add('is-open');
    }

    close() {
        clearTimeout(this.appendDelay)

        this.el.classList.remove('is-open')
    }
}
