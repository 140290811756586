import { module } from 'modujs';

const DRAG_START_EVENTS = ['mousedown', 'touchdown']
const DRAG_MOVE_EVENTS = ['mousemove', 'touchmove']
const DRAG_END_EVENTS = ['mouseup', 'touchup']

export default class extends module {
    constructor(m) {
        super(m);

        this.pos = {
            left: 0,
            x: 0
        }
    }

    init() {

        // Events
        this.onDragStart = this.dragStartHandler.bind(this)
        this.onDragMove = this.dragMoveHandler.bind(this)
        this.onDragEnd = this.dragEndHandler.bind(this)

        DRAG_START_EVENTS.forEach(e => {
            this.el.addEventListener(e, this.onDragStart, false);
        });
    }

    dragStartHandler(e) {

        // Add grabbing styles
        this.el.style.cursor = 'grabbing';
        this.el.style.userSelect = 'none';

        // Set position
        this.pos = {
            left: this.el.scrollLeft,
            x: e.clientX,
        };

        // Add events

        DRAG_MOVE_EVENTS.forEach(e => {
            document.addEventListener(e, this.onDragMove, false);
        });

        DRAG_END_EVENTS.forEach(e => {
            document.addEventListener(e, this.onDragEnd, false);
        });
    }

    dragMoveHandler(e) {
        // Update position
        const dx = e.clientX - this.pos.x;

        // Scroll the element
        this.el.scrollLeft = this.pos.left - dx;
    }

    dragEndHandler() {

        // Remove grabbing styles
        this.el.style.cursor = 'grab';
        this.el.style.removeProperty('user-select');

        // Remove events
        DRAG_MOVE_EVENTS.forEach(e => {
            document.removeEventListener(e, this.onDragMove);
        });

        DRAG_END_EVENTS.forEach(e => {
            document.removeEventListener(e, this.onDragEnd);
        });
    }

    destroy() {

        DRAG_START_EVENTS.forEach(e => {
            this.el.removeEventListener(e, this.onDragStart);
        });
    }
}
