import { module } from 'modujs';
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { MODAL } from '../utils/constants'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        const _self = this

        this.vm = createApp({
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                return {
                    email: '',
                }
            },
            validations() {
                return {
                    email: {
                        required,
                        email
                    },
                }
            },
            // mounted: () => {
            //     this.call('update', null, 'Scroll')
            // },
            methods: {
                async submit () {
                    const isFormCorrect = await this.v$.$validate()

                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form

                    // TEMPORARY
                    alert('submit forgot password form')
                    _self.call('close', MODAL.FORGOT_PASSWORD, 'Modal')
                }
            },
        }).mount(this.el)
    }
}
