import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$toggle = this.$('toggle')[0]
        this.$items = Array.from(this.$('item'))
    }

    init() {

        this.$toggle.addEventListener('change', this.toggle.bind(this))
    }

    toggle() {

        // Show files only
        if(this.$toggle.checked) {
            const $itemsWithoutFile = this.$items.filter($item => !$item.querySelector('[data-module-file]'))
            for(let $item of $itemsWithoutFile) {
                $item.classList.add('is-hidden')
            }
        // Show all
        } else {
            for(let $item of this.$items) {
                $item.classList.remove('is-hidden')
            }
        }

        // Update scroll
        requestAnimationFrame(() => {
            this.call('update', null, 'Scroll')
        })
    }

    destroy() {

        this.$toggle.removeEventListener('change', this.toggle)
    }
}
