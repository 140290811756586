import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$action = this.$('action')[0]
        // this.$sign = this.$('sign')[0]
        // this.$delete = this.$('delete')[0]
        // this.$download = this.$('download')[0]

        this.isOpen = false

        this.events = {
            click: {
                'open': 'open',
                'close': 'close',
                'sign' : 'sign',
                'delete' : 'delete',
                'download' : 'download',
            }
        }
    }

    init() {

    }

    toggle() {

        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        this.isOpen = true
        this.$action.classList.add('is-open')

        setTimeout(() => {
            window.addEventListener('click', this.bindClick = (e) => {
                if (!this.$action.contains(e.target)){
                    this.close()
                }
            })
        }, 10)
    }

    close() {
        this.isOpen = false
        this.$action.classList.remove('is-open')

        window.removeEventListener('click', this.bindClick)
    }

    // Actions
    sign() {
        window.alert('Sign')
    }

    delete() {
        window.alert('Delete')
    }

    download() {
        window.alert('Download')
    }
}
