import { module } from 'modujs';
import modularLoad from 'modularload';
import { MODAL } from '../utils/constants'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const load = new modularLoad({
            enterDelay: 1000,
            transitions: {
                customTransition: {}
            }
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            this.call('close', MODAL.PROFILE, 'Modal')
            this.call('close', MODAL.FORGOT_PASSWORD, 'Modal')
        });
    }
}
