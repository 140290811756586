import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';
import debounce from './utils/debounce'

const app = new modular({
    modules: modules
});

window.onload = (event) => {
    const $style = document.getElementById('main-css');

    // Add resizeEnd event to window
    const resizeEndEvent = new CustomEvent('resizeEnd');
    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEndEvent)
    }, 200, false))

    if ($style) {
        if ($style.isLoaded) {
            init();
        } else {
            $style.addEventListener('load', (event) => {
                init();
            });
        }
    } else {
        console.warn('The "main-css" stylesheet not found');
    }
};

function init() {
    globals();

    app.init(app);

    // Wait for loader animation to finish to hide it (1500ms)
    const loadingTime = (new Date() - window.timeStamp)
    const delay = Math.max(0, 1500 - loadingTime)

    setTimeout(() => {
        html.classList.add('is-loaded');
        html.classList.remove('is-loading');

        setTimeout(() => {
            html.classList.add('is-ready');
        }, 1000);
    }, delay);
}

