import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.$input = this.$('input')[0]
        this.$icon = this.$('icon')[0]
    }

    init() {
        this.$icon.addEventListener('mousedown', this.onMouseDown = () => this.showPassword())
    }

    showPassword() {
        this.$input.setAttribute('type', 'text');
        document.addEventListener('mouseup', this.onMouseUp = () => this.hidePassword())
    }

    hidePassword() {
        this.$input.setAttribute('type', 'password');
    }

    destroy() {
        this.$icon.removeEventListener('mousedown', this.onMouseDown)
        document.removeEventListener('mouseup', this.onMouseUp)
    }
}
