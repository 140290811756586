import { module } from 'modujs';
import { SplitText } from 'gsap/all'

export default class extends module {
    constructor(m) {
        super(m);

        this.block = this.el.getAttribute('data-text-reveal-block') !== null
    }

    init() {
        this.el.classList.add('o-text-reveal')

        if(this.block) {

            this.el.classList.add('-block')
            return
        }

        // Split lines
        this.initSplits()

        // Add number of lines props to container
        const totalLines = this.split.lines.length
        this.el.style.setProperty('--text-reveal-total-lines', totalLines);

        // Add line index to each lines
        this.split.lines.forEach(($line, i) => {
            $line.style.setProperty('--text-reveal-line-index', i)
        })


        window.addEventListener('resizeEnd', this.onResize = () => {
            this.revertSplits()
            this.initSplits()
        })
    }

    initSplits() {

        // Split Text lines
        this.splitInner = new SplitText(this.el, {
            type: 'lines',
            linesClass: 'o-text-reveal_line-inner',
        })

        // Wrap lines
        this.split = new SplitText(this.el, {
            type: 'lines',
            linesClass: 'o-text-reveal_line',
        })

    }

    revertSplits() {
        this.split.revert()
        this.splitInner.revert()
    }

    destroy() {
        window.removeEventListener('resizeEnd', this.onResize)
    }
}
