import { module } from 'modujs';
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import { required, email, sameAs, minLength } from '@vuelidate/validators'
import { MODAL } from '../utils/constants'

const DEFAULT_FORM_VALUES = {
    lastname: 'Doe',
    name: 'John',
    email: 'johndoe@gmail.com',
    phone: '',
    address: '',
    province: '',
    postalCode: '',
    date: '',
    password: '1234567890'
}


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const _self = this

        this.vm = createApp({
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                const fields = {
                    lastname: {
                        label: 'Last name',
                        value: DEFAULT_FORM_VALUES.lastname,
                        type: 'text',
                        half: true,
                        validation: () => ({
                            required
                        })
                    },
                    name: {
                        label: 'Name',
                        value: DEFAULT_FORM_VALUES.name,
                        type: 'text',
                        half: true,
                        validation: () => ({
                            required
                        })
                    },
                    email: {
                        label: 'Email address',
                        value: DEFAULT_FORM_VALUES.email,
                        type: 'email',
                        half: true,
                        validation: () => ({
                            required,
                            email
                        })
                    },
                    emailConfirmation: {
                        label: 'Confirm email address',
                        value: DEFAULT_FORM_VALUES.email,
                        type: 'email',
                        half: true,
                        validation: () => ({
                            required,
                            sameAs: sameAs(this.email, 'email')
                        })
                    },
                    phone: {
                        label: 'Phone number',
                        value: DEFAULT_FORM_VALUES.phone,
                        type: 'tel',
                        half: true,
                        validation: () => ({
                            required,
                        })
                    },
                    address: {
                        label: 'Property Street Address',
                        value: DEFAULT_FORM_VALUES.address,
                        type: 'text',
                        half: true,
                        placeholder: 'Address',
                        validation: () => ({
                            required,
                        })
                    },
                    addressConfirmation: {
                        label: 'City',
                        value: DEFAULT_FORM_VALUES.address,
                        type: 'text',
                        half: true,
                        validation: () => ({
                            required,
                        })
                    },
                    province: {
                        label: 'Province',
                        value: DEFAULT_FORM_VALUES.province,
                        type: 'text',
                        half: true,
                        placeholder: 'Ontario',
                        validation: () => ({})
                    },
                    postalCode: {
                        label: 'Postal code',
                        value: DEFAULT_FORM_VALUES.postalCode,
                        type: 'text',
                        half: true,
                        placeholder: 'T12 345',
                        validation: () => ({})
                    },
                    date: {
                        label: 'Closing date',
                        value: DEFAULT_FORM_VALUES.date,
                        type: 'date',
                        half: true,
                        placeholder: 'YYYY-MM-DD',
                        validation: () => ({})
                    },
                    password: {
                        label: 'Password',
                        value: DEFAULT_FORM_VALUES.password,
                        type: 'password',
                        half: true,
                        validation: () => ({
                            required,
                            minLength: minLength(8)
                        })
                    },
                    passwordConfirmation: {
                        label: 'Confirm password',
                        value: DEFAULT_FORM_VALUES.password,
                        type: 'password',
                        half: true,
                        validation: () => ({
                            required,
                            sameAs: sameAs(this.password, 'password')
                        })
                    },
                }

                const inputs = {}

                let value
                for(let key in fields) {
                    value = fields[key].value
                    inputs[key] = value ? value : ''
                }

                return {
                    ...inputs,
                    fields,
                }
            },
            validations() {
                const validations = {}

                for(let key in this.fields) {
                    validations[key] = this.fields[key].validation()
                }

                return validations
            },
            methods: {
                showPassword(field) {
                    field.type = 'text'

                    const hidePassword = () => {
                        field.type = 'password'
                        document.removeEventListener('mouseup', hidePassword)
                    }

                    document.addEventListener('mouseup', hidePassword)
                },
                async submit () {
                    const isFormCorrect = await this.v$.$validate()
                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form

                    // TEMPORARY
                    alert('submit profile form')
                    _self.call('close', MODAL.PROFILE, 'Modal')
                }
            },
        }).mount(this.el)
    }
}
