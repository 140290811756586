import { module } from 'modujs';


export default class extends module {
    constructor(m) {
        super(m);

        this.ctrlDown = false;
        this.active = false;

        this.fontSize = parseInt(this.getData('font-size')) || 16;
        this.gap = parseInt(this.getData('gap')) || 10;
        this.remGap = this.gap/this.fontSize;

        this.cols = parseInt(this.getData('cols')) || 20
        const [r,g,b] = this.getData('color').split(',') || [255,0,0]
        this.color = {r,g,b};
    }

    init() {
        this.setStyle()
        this.populate()

        document.addEventListener('keydown', (e) => {
            if(e.key == 'Control') {
                this.ctrlDown = true;
            } else {
                if(this.ctrlDown && e.key == 'g') {
                    if(!this.active) {
                        this.active = true;
                        this.el.style.visibility = 'visible';
                    } else {
                        this.active = false;
                        this.el.style.visibility = 'hidden'
                    }
                }
            }
        })

        document.addEventListener('keyup', (e) => {
            if(e.key == 'Control') {
                this.ctrlDown = false
            }
        })
    }

    setStyle() {
        this.el.style.position = "fixed";
        this.el.style.top = "0";
        this.el.style.left = "0";
        this.el.style.width = "100%";
        this.el.style.height = "100%";
        this.el.style.zIndex = "9999";
        this.el.style.visibility = 'hidden'
        this.el.style.pointerEvents = "none";
    }

    populate() {
        this.el.innerHTML = '';

        for(let i = 0; i < this.cols; i++) {
            let col = document.createElement('span')
            col.style.display = "inline-block";
            col.style.height = "100%";
            col.style.outline = `1px solid rgba(${this.color.r},${this.color.g},${this.color.b},0.2)`;

            if(i == 0)
                col.style.marginLeft = `${this.remGap}rem`;

            col.style.marginRight = `${this.remGap}rem`;

            col.style.width = `calc((100vw - (${this.remGap}rem*${this.cols+1})) * 1/${this.cols}`
            col.style.backgroundColor = `rgba(${this.color.r},${this.color.g},${this.color.b},0.1)`;
            this.el.appendChild(col)
        }
    }
}
