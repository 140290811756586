// Modular
import { module } from 'modujs';

// Animation
import { gsap } from 'gsap/all'

// Vue
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, email, sameAs, minLength } from '@vuelidate/validators'
import vueFilePond from "vue-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// Utils
import { isChecked, fieldsFromArray } from '../utils/vuelidate-form'


const SERVICES = [
    {
        title: 'Selling a Home',
        price: 999,
        checklist: [
            'Online ID verification and Remote or In-Person Mobile Signing',
            'Personal transaction dashboard ',
            'Stay connected with instant messenger'
        ]
    },
    {
        title: 'Buying a Home',
        price: 1099,
        checklist: [
            'Online ID verification and Remote or In-Person Mobile Signing',
            'Personal transaction dashboard ',
            'Stay connected with instant messenger'
        ]
    },
    {
        title: 'Refinancing a Home',
        price: 799,
        checklist: [
            'Online ID verification and Remote or In-Person Mobile Signing',
            'Personal transaction dashboard',
            'Stay connected with instant messenger'
        ]
    },
    {
        title: 'Transfers a Home',
        price: 899,
        checklist: [
            'Online ID verification and Remote or In-Person Mobile Signing',
            'Instant messenger',
            'Access to personal dashboard'
        ]
    }
];


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const _self = this

        // Create filepond component
        const FilePond = vueFilePond(
            FilePondPluginFileValidateType,
        );

        this.vm = createApp({
            components: {
                FilePond,
            },
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                const sections = [
                    {
                        isOpen: false,
                        isCompleted: false,
                        title: 'Define your transaction',
                        desc: 'OPL offers intuitive features that make your transactions transparent, secure and widely accessible.',
                        fields: {
                            cards: {
                                type: 'cards',
                                items: SERVICES,
                                validation: () => ({
                                    required
                                })
                            }
                        },
                    },
                    {
                        isOpen: false,
                        isCompleted: false,
                        title: 'General Information',
                        fields: {
                            lastname: {
                                label: 'Last name',
                                type: 'text',
                                half: true,
                                validation: () => ({
                                    required
                                })
                            },
                            name: {
                                label: 'Name',
                                type: 'text',
                                half: true,
                                validation: () => ({
                                    required
                                })
                            },
                            email: {
                                label: 'Email address',
                                type: 'email',
                                half: true,
                                validation: () => ({
                                    required,
                                    email
                                })
                            },
                            emailConfirmation: {
                                label: 'Confirm email address',
                                type: 'email',
                                half: true,
                                validation: () => ({
                                    required,
                                    sameAs: sameAs(this.email, 'email')
                                })
                            },
                            phone: {
                                label: 'Phone number',
                                type: 'tel',
                                half: true,
                                validation: () => ({
                                    required,
                                })
                            },
                            address: {
                                label: 'Property Street Address',
                                type: 'text',
                                half: true,
                                placeholder: 'Address',
                                validation: () => ({
                                    required,
                                })
                            },
                            addressConfirmation: {
                                label: 'City',
                                type: 'text',
                                half: true,
                                validation: () => ({
                                    required,
                                })
                            },
                            province: {
                                label: 'Province',
                                type: 'text',
                                half: true,
                                placeholder: 'Ontario',
                                validation: () => ({})
                            },
                            postalCode: {
                                label: 'Postal code',
                                type: 'text',
                                half: true,
                                placeholder: 'T12 345',
                                validation: () => ({})
                            },
                            date: {
                                label: 'Closing date',
                                type: 'date',
                                half: true,
                                placeholder: 'YYYY-MM-DD',
                                validation: () => ({})
                            },
                            password: {
                                label: 'Password',
                                type: 'password',
                                half: true,
                                validation: () => ({
                                    required,
                                    minLength: minLength(8)
                                })
                            },
                            passwordConfirmation: {
                                label: 'Confirm password',
                                type: 'password',
                                half: true,
                                validation: () => ({
                                    required,
                                    sameAs: sameAs(this.password, 'password')
                                })
                            },
                            notification: {
                                desc: 'Get notifications for when you receive a new message- auto email, text and phone.',
                                type: 'checkbox-group',
                                value: '',
                                checkboxes: [
                                    'Phone',
                                    'Text',
                                    'Email'
                                ],
                                validation: () => ({
                                    required,
                                })
                            },
                        }
                    },
                    {
                        isOpen: false,
                        isCompleted: false,
                        title: 'ID Documents',
                        desc: 'Verify your identity online with your government-issued ID.',
                        fields: {
                            file: {
                                type: 'file',
                                value: [],
                                filePondSettings: {
                                    labelIdle: `
                                        <i class="o-icon">
                                            <svg class="svg-arrow-long-up">
                                                <use xlink:href="assets/images/sprite.svg#arrow-long-up"></use>
                                            </svg>
                                        </i>
                                        Drag and drop or choose a file
                                        <small>PDF - 10MB Max</small>
                                    `,
                                    className: 'o-input-file',
                                    allowMultiple: true,
                                    required: true,
                                    credits: null,
                                    acceptedFileTypes: 'application/pdf',
                                    styleButtonRemoveItemPosition: 'right'
                                },
                                validation: () => ({
                                    required: requiredIf(() => this.file.length === 0)
                                })
                            },
                            adult: {
                                label: 'I certify I am 18 years old and have read and accepted the <a href="" title="" class="o-link">Terms and Conditions</a>, and <a href="" title="" class="o-link">Privacy Policy</a>.',
                                type: 'checkbox',
                                value: true,
                                half: true,
                                breakLine: true,
                                validation: () => ({
                                    required,
                                    isChecked
                                })
                            },
                            policy: {
                                label: 'I have read the <a href="" title="" class="o-link">Contact Policy</a> and I agree to receive text and emails from Ontario Property Lawyers with security alerts about my acount. You may receive messages on a variable frequency. Reply help or stop to cancel.',
                                type: 'checkbox',
                                value: true,
                                half: true,
                                breakLine: true,
                                validation: () => ({
                                    required,
                                    isChecked
                                })
                            },
                        }
                    },
                ]

                const inputs = fieldsFromArray(sections.map(s => s.fields))

                return {
                    activeSectionIndex: -1,
                    activeCard: null,
                    sectionRefs: [],
                    cardRefs: [],
                    ...inputs,
                    sections
                }
            },
            mounted() {

                // Set sizes
                this.resize()

                // Update scroll
                this.updateScroll()

                // Show first section
                this.toggleSection(0)

                window.addEventListener('resizeEnd', this.onResize = () => this.resize())
            },
            validations() {
                const validations = {}

                for(let fields of this.sections.map(s => s.fields)) {
                    for(let key in fields) {
                        validations[key] = fields[key].validation()
                    }
                }

                return validations
            },
            computed: {
                currentSection() {
                    return this.activeSectionIndex >= 0 ? this.sections[this.activeSectionIndex] : null
                },
                hasCurrentSection() {
                    return this.currentSection !== null
                },
                progress() {
                    return (this.activeSectionIndex)/(this.sections.length - 1) * 100
                }
            },
            watch: {
                v$(value) {

                    if(!this.hasCurrentSection) {
                        return
                    }

                    this.currentSection.isCompleted = !Object.keys(this.currentSection.fields).find(field => this.v$[field].$invalid)
                }
            },
            methods: {
                onUpdateFiles(sectionIndex, key, files) {

                    const acceptedFileTypes = this.sections[sectionIndex].fields[key].filePondSettings.acceptedFileTypes
                    const filesArray = []
                    let fileType
                    files.forEach(file => {
                        fileType = file.fileType
                        if(fileType && acceptedFileTypes.includes(fileType)) {
                            filesArray.push(file)
                        }
                    })

                    // Remove empty files
                    this.v$[key].$model = filesArray
                },
                setRef(refArray, el) {
                    refArray.push(el)
                },
                showPassword(field) {
                    field.type = 'text'

                    const hidePassword = () => {
                        field.type = 'password'
                        document.removeEventListener('mouseup', hidePassword)
                    }

                    document.addEventListener('mouseup', hidePassword)
                },
                setActiveCard(id) {
                    this.activeCard = this.activeCard === id ? null : id
                    this.updateScroll(800)
                },
                selectCard(key, value) {
                    this.v$[key].$model = value
                },
                fileUpdate(key, e) {
                    var files = e.target.files || e.dataTransfer.files;
                    if (!files.length) {
                        return;
                    }
                    console.log('fileUpdate', key, e, files)
                    this.v$[key].$model = files;
                },
                checkboxesUpdate(key, e) {
                    const $target = e.target
                    const checked = $target.checked
                    const value = $target.value

                    const arrayValue = this.v$[key].$model === '' ? [] : this.v$[key].$model.split(', ')

                    if(checked) {
                        arrayValue.push(value)
                    } else {
                        const index = arrayValue.indexOf(value)
                        if(index > -1) {
                            arrayValue.splice(index, 1)
                        }
                    }

                    this.v$[key].$model = arrayValue.join(', ')
                },
                toggleSection(index) {

                    // Close previous section
                    if(this.hasCurrentSection) {
                        this.currentSection.isOpen = false
                    }

                    // Open new section
                    this.activeSectionIndex = index
                    this.currentSection.isOpen = true

                    this.updateScroll(800)
                },
                resize() {

                    let height
                    [...this.sectionRefs, ...this.cardRefs].forEach(card => {
                        height = card.querySelector('.c-squeeze-box_content').offsetHeight
                        card.style.setProperty('--squeeze-box-height', `${height}px`)
                    })
                },
                updateScroll(delay=0, cb=function(){}) {

                    // Update scroll position
                    setTimeout(() => {
                        cb()
                        _self.call('update', null, 'Scroll')
                    }, delay);
                },
                async submit () {
                    const isFormCorrect = await this.v$.$validate()
                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form


                    // TEMPORARY
                    alert('submit signup form')
                    window.location.href = '/feedback.php'
                }
            }
        }).mount(this.el)

    }
}
