import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'open' : 'open'
            }
        }

        this.modalId = this.getData('target')
    }

    open(e) {
        e.stopPropagation()
        this.call('open', this.modalId, 'Modal')
    }
}
