import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';
import { queryClosestParent } from '../utils/html';
// import { lazyLoadImage } from '../utils/image';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            getDirection: true
        });

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {
            const headerArgs = {
                y: args.scroll.y,
                down: args.direction === 'down'
            }

            this.call('onScroll', headerArgs, 'Header')
        })
    }

    toggleLazy(args) {
        const $el = args.obj.el
        const src = this.getData('lazy', $el)

        if (src.length) {
            if ($el.tagName === 'IMG') {
                $el.src = src
            } else {
                $el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', $el)

            let lazyParent = queryClosestParent($el, '.o-image-lazy')
            if(lazyParent) {
                lazyParent.classList.add('is-loaded')
            } else {
                $el.classList.add('is-loaded')
            }
        }
    }

    update() {
        this.scroll.update()
    }

    destroy() {
        this.scroll.destroy();
    }
}
