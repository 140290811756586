import { module } from 'modujs';
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import { isChecked } from '../utils/vuelidate-form'

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        this.vm = createApp({
            setup() {
                return { v$: useVuelidate() }
            },
            data() {
                return {
                    privacy: false,
                    contact: false,
                }
            },
            validations() {
                return {
                    privacy: {
                        isChecked
                    },
                    contact: {
                        isChecked
                    },
                }
            },
            mounted: () => {
                this.call('update', null, 'Scroll')
            },
            methods: {
                async submit () {
                    const isFormCorrect = await this.v$.$validate()

                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form

                    // TEMPORARY
                    alert('submit terms form')
                    window.location.href = '/signup.php'
                }
            },
        }).mount(this.el)
    }
}
