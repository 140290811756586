import { module } from 'modujs';
import { gsap } from 'gsap/all';

export default class extends module {
    constructor(m) {
        super(m);

        this.$inner = this.$('inner')[0]
        this.$indicator = this.$('indicator')[0]
        this.$list = this.$('list')[0]
        this.$items = Array.from(this.$('item'))
        this.totalItems = this.$items.length

        // Events
        this.events = {
            click: {
                'item': 'selectItem'
            },
            mouseleave: {
                'list': 'mouseLeave',
            }
        }
    }

    init() {


        this.hasMotion = true

        // Find current index
        this.currentIndex = this.$items.findIndex($item => $item.classList.contains('is-current'))

        // Define sizes
        this.setSizes()

        // Animate indicator on each item mouseenter
        this.itemsEvent = []
        this.$items.forEach(($item, i) => {
            this.itemsEvent.push(() => this.hoverIn(i))
            $item.addEventListener('mouseenter', this.itemsEvent[i])
        })

        // Window event
        window.addEventListener('resizeEnd', this.setSizes.bind(this))
    }

    setSizes() {
        const styles = getComputedStyle(this.el)
        this.isMobile = styles.getPropertyValue('--nav-mobile') == 1;

        if(this.isMobile) {
            this.listWidth = this.$list.offsetWidth
        } else {
            this.itemSize = parseInt(styles.getPropertyValue('--nav-item-size'), 10);
        }

        this.hoverIn(this.currentIndex, 0)
    }

    hoverIn(i, duration=1) {

        if(!this.hasMotion) {
            return
        }

        let x = 0
        let y = 0
        if(this.isMobile) {
            x = this.listWidth/this.totalItems * i + this.listWidth/(this.totalItems * 2)
        } else {
            y = i * this.itemSize
        }

        gsap.to(this.$indicator, {
            x,
            y,
            duration,
            ease: 'expo.out',
        })

    }

    selectItem(e) {
        this.hasMotion = false
        const $currentIrem = this.$items.find($item => $item.classList.contains('is-current'))
        $currentIrem.classList.remove('is-current')
        e.curTarget.classList.add('is-current')
    }

    mouseLeave() {
        this.hoverIn(this.currentIndex)
    }

    destroy() {
        window.removeEventListener('resizeEnd', this.setSizes)

        this.$items.forEach(($item, i) => {
            $item.removeEventListener('mouseenter', this.itemsEvent[i])
        })
    }
}
