// Modular
import { module } from 'modujs';

// Animation
import { gsap } from 'gsap/all'

// Vue
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import useVuelidate from '@vuelidate/core'
import vueFilePond from "vue-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const _self = this

        // Create filepond component
        const FilePond = vueFilePond(
            FilePondPluginFileValidateType,
        );

        this.vm = createApp({
            components: {
                FilePond,
            },
            setup() {
                return { v$: useVuelidate() }
            },
            data: () => ({
                file: {
                    type: 'file',
                    value: [],
                    filePondSettings: {
                        labelIdle: `
                            <i class="o-icon">
                                <svg class="svg-arrow-long-up">
                                    <use xlink:href="assets/images/sprite.svg#arrow-long-up"></use>
                                </svg>
                            </i>
                            Drag and drop or choose a file
                            <small>PDF - 10MB Max</small>
                        `,
                        className: 'o-input-file',
                        allowMultiple: false,
                        required: true,
                        credits: null,
                        acceptedFileTypes: 'application/pdf',
                        styleButtonRemoveItemPosition: 'right'
                    },
                }
            }),
            validations() {
                return {
                    file: {},
                }
            },
            methods: {
                onUpdateFiles(files) {

                    const acceptedFileTypes = this.file.filePondSettings.acceptedFileTypes
                    const filesArray = []
                    let fileType
                    files.forEach(file => {
                        fileType = file.fileType
                        if(fileType && acceptedFileTypes.includes(fileType)) {
                            filesArray.push(file)
                        }
                    })

                    // Remove empty files
                    this.v$.file.$model = filesArray
                },
                fileUpdate(key, e) {
                    var files = e.target.files || e.dataTransfer.files;
                    if (!files.length) {
                        return;
                    }
                    this.v$.file.$model = files;
                },
                async submit () {
                    const isFormCorrect = await this.v$.$validate()
                    // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                    if (!isFormCorrect) return
                    // actually submit form


                    // TEMPORARY
                    alert('submit file')
                }
            }
        }).mount(this.el)

    }
}
