/******************************************
 *
 * Vuelidate utils
 *
 ******************************************/


/**
 * Vuelidate checkbox validation
 * @param  {boolean} The checkbox value
 * @return {boolean} True if the checkbox is checked
 */

export const vuelidateForm = {
}

/**
 * Checkbox validation
 * @param  {boolean} The checkbox value
 * @return {boolean} True if the checkbox is checked
 */

export function isChecked(value) {
    return value === true
}


/**
 * Create
 * @param  {boolean} The checkbox value
 * @return {boolean} True if the checkbox is checked
 */

export function fieldsFromArray(fieldsArray) {

    const inputs = {}

    let value
    for(let fields of fieldsArray) {
        for(let key in fields) {
            value = fields[key].value
            inputs[key] = value ? value : ''
        }
    }

    return inputs
}
