import { module } from 'modujs';
import { gsap } from 'gsap/all'

export default class extends module {
    constructor(m) {
        super(m);

        this.ACTIVE_CLASS = 'is-open'

        this.$cards = Array.from(this.$('card'))
        this.$headers = Array.from(this.$('header'))
        this.$contents = Array.from(this.$('content'))
        this.$lists = Array.from(this.$('list'))

        // Add events
        this.events = {
            click: {
                'header': 'toggle',
                'content': 'toggle',
            },
            mouseenter: {
                'card': 'open'
            },
            mouseleave: {
                'card': 'close'
            }
        }

        window.addEventListener('resizeEnd', this.set.bind(this))
        this.set()
    }

    set() {
        const elStyles = this.el.style

        elStyles.setProperty('--card-height', 'auto')
        elStyles.setProperty('--card-header-height', 'auto')
        elStyles.setProperty('--card-content-height', 'auto')

        let cardMaxHeight = 0;
        let headerMaxHeight = 0;
        let contentMaxHeight = 0;

        this.$cards.forEach(($card, i) => {
            const cardHeight = $card.offsetHeight
            const headerHeight = this.$headers[i].offsetHeight
            const contentHeight = this.$lists[i].offsetHeight

            if(cardHeight > cardMaxHeight) {
                cardMaxHeight = cardHeight
            }

            if(headerHeight > headerMaxHeight) {
                headerMaxHeight = headerHeight
            }

            if(contentHeight > contentMaxHeight) {
                contentMaxHeight = contentHeight
            }
        })

        elStyles.setProperty('--card-height', `${cardMaxHeight}px`)
        elStyles.setProperty('--card-header-height', `${headerMaxHeight}px`)
        elStyles.setProperty('--card-content-height', `${contentMaxHeight}px`)

    }

    toggle(e) {

        // Replace current target to parent
        const $card = this.parent('card', e.curTarget)
        const isOpen = $card.classList.contains(this.ACTIVE_CLASS)

        e = {
            curTarget: $card
        }

        if(isOpen) {
            this.close(e)
        } else {
            this.open(e)
        }
    }

    open(e) {
        console.log('open', e)

        const $card = e.curTarget
        $card.classList.add(this.ACTIVE_CLASS)

        // // Get content height
        // const height = this.list.offsetHeight

        // console.log('open', height)

        // // Slide down and show content
        // gsap.to(this.content, {
        //     duration: 1,
        //     height,
        //     opacity: 1,
        //     ease: 'expo.inOut',
        //     onComplete: () => {
        //         gsap.set(this.content, { height: 'auto' })

        //         // Update scroll position
        //         this.call('update', null, 'Scroll')
        //     }
        // })

        // // Add active class
        // this.el.classList.add(this.ACTIVE_CLASS);

        // this.isOpen = true
    }

    close(e) {

        const $card = e.curTarget
        $card.classList.remove(this.ACTIVE_CLASS)

        // // Slide up and hide content
        // gsap.to(this.content, {
        //     duration: 1,
        //     height: 0,
        //     opacity: 0,
        //     ease: 'expo.inOut',
        //     onComplete: () => {
        //         // Update scroll position
        //         this.call('update', null, 'Scroll')
        //     }
        // })

        // // Remove active class
        // this.el.classList.remove(this.ACTIVE_CLASS);

        // this.isOpen = false
    }

    destroy() {
        window.removeEventListener('resizeEnd', this.set)
    }
}
